import React from "react"
import { injectIntl } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PrivacyPolicy = ({ intl }) => (
  <Layout>
    <SEO title={intl.formatMessage({ id: "privacy-policy" })} />
    <div class="container pt-xs-50 pb-xs-50">
      <div class="col col-md-8 offset-md-2">
        <h1>enigmibot Privacy Policy (26th Jan 2019)</h1>

        <p>This Privacy Policy describes how your personal information is collected, used, and shared when you use, visit or make a purchase from https://www.enigmibot.com/ or the enigmibot app, from now called  "enigmibot".</p>

        <h2>Thank you for reading</h2>
        <p>I'm not a lawyer, and probably neither are you, if you have any question about the following please write to enigmibot@obsidianart.com. I'm always happy to hear suggestions.</p>

        <h2>Personal information we collect</h2>

        <p>When you interact with enigmibot, we automatically collect certain information about your device, including information about your device, web browser, IP address, time zone, and some of the cookies that are installed on your device. Additionally, as you use enigmibot, we collect information about the individual web pages or products that you view, what websites, app stores or search terms referred you to enigmibot, and information about how you interact with enigmibot. We refer to this automatically-collected information as “Device Information.”</p>

        <p>We collect Device Information using the following technologies:</p>
        <ul>
          <li>“Cookies” are data files that are placed on your device or computer and often include an anonymous unique identifier. For more information about cookies, and how to disable cookies, visit http://www.allaboutcookies.org.</li>
          <li>“Log files” track actions occurring on enigmibot, and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps.</li>
          <li>“Web beacons,” “tags,” and “pixels” are electronic files used to record information about how you interact enigmibot.</li>
        </ul>
        <p>Additionally when you make a purchase or attempt to make a purchase through enigmibot, we may collect certain information from you, including your name, billing address, shipping address, payment information, email address, and phone number.  We refer to this information as “Order Information.”</p>

        <p>When we talk about “Personal Information” in this Privacy Policy, we are talking both about Device Information and Order Information.</p>

        <h2>HOW DO WE USE YOUR PERSONAL INFORMATION?</h2>

        <p>We use the Order Information that we collect generally to fulfill any orders placed through enigmibot (including processing your payment information, arranging for shipping, and providing you with invoices and/or order confirmations).  Additionally, we use this Order Information to:</p>
        <ul>
          <li>Communicate with you;</li>
          <li>Screen our orders for potential risk or fraud; and</li>
          <li>When in line with the preferences you have shared with us, provide you with information or advertising relating to our products or services.</li>
        </ul>
        <p>We use the Device Information that we collect to help us screen for potential risk and fraud (in particular, your IP address), and more generally to improve and optimize our Site/App (for example, by generating analytics about how our customers browse and interact with enigmibot, and to assess the success of our marketing and advertising campaigns).</p>


        <h2>SHARING YOUR PERSONAL INFORMATION</h2>

        <p>We may share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.</p>

        <h2>BEHAVIOURAL ADVERTISING</h2>
        <p>As described above, we may use your Personal Information to provide you with targeted advertisements or marketing communications we believe may be of interest to you.  For more information about how targeted advertising works, you can visit the Network Advertising Initiative’s (“NAI”) educational page at http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.</p>

        <p>You can opt out of targeted advertising by:</p>
        <h5>INCLUDE OPT-OUT LINKS FROM WHICHEVER SERVICES BEING USED. COMMON LINKS INCLUDE:</h5>
        <ul>
          <li>FACEBOOK - https://www.facebook.com/settings/?tab=ads</li>
          <li>GOOGLE - https://www.google.com/settings/ads/anonymous</li>
          <li>BING - https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads</li>
        </ul>
        <p>Additionally, you can opt out of some of these services by visiting the Digital Advertising Alliance’s opt-out portal at:  http://optout.aboutads.info/.</p>

        <h3>DO NOT TRACK</h3>
        <p>Please note that we do not alter our Site’s data collection and use practices when we see a Do Not Track signal from your browser.</p>

        <h3>YOUR RIGHTS</h3>
        <p>If you are a European resident, you have the right to access personal information we hold about you and to ask that your personal information be corrected, updated, or deleted. If you would like to exercise this right, please contact us through the contact information below.</p>
        <p>Additionally, if you are a European resident we note that we are processing your information in order to fulfill contracts we might have with you (for example if you make an order through enigmibot), or otherwise to pursue our legitimate business interests listed above.  Additionally, please note that your information will be transferred outside of Europe, including to Canada and the United States.</p>

        <h3>DATA RETENTION</h3>
        <p>When you complete an action on enigmibot, we will maintain a log for our records.</p>

        <h3>ADDITIONAL INFORMATION</h3>
        <p>We may share data with 3rd party services e.g. Google Play Store, Apple App Store or other stores, in order to improve the quality and efficiency of this service.</p>

        <h3>Liability Disclaimer </h3>
        <p>
          THE INFORMATION, SOFTWARE, SERVICES, CONTENT AND RELATED MATERIALS INCLUDED IN OR AVAILABLE THROUGH THE enigmibot MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE INFORMATION HEREIN. COMPANY AND/OR ITS SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR CHANGES IN enigmibot AT ANY TIME.
          COMPANY AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY OF THE INFORMATION, SOFTWARE, SERVICES, CONTENT AND RELATED MATERIAL CONTAINED ON enigmibot. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH INFORMATION, SOFTWARE, SERVICES, CONTENT AND RELATED MATERIAL ARE PROVIDED "AS IS" WITHOUT WARRANTY OR CONDITION OF ANY KIND. COMPANY AND/OR ITS SUPPLIERS HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH REGARD TO THE INFORMATION, SOFTWARE, SERVICES, CONTENT AND RELATED MATERIALS CONTAINED HEREIN, INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.
          TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL COMPANY AND/OR ITS SUPPLIERS BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE, DATA OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED (A) WITH THE USE OR PERFORMANCE OF enigmibot, (B) WITH THE DELAY OR INABILITY TO USE enigmibot OR RELATED SERVICES, (C) THE PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR FOR ANY INFORMATION, SOFTWARE, SERVICES, CONTENT AND RELATED MATERIALS OBTAINED THROUGH enigmibot, (D) STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON enigmibot , OR (E) OTHERWISE ARISING OUT OF THE USE OF enigmibot, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF COMPANY OR ANY OF ITS SUPPLIERS HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED WITH ANY PORTION OF enigmibot, OR WITH ANY OF THESE TERMS, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING enigmibot.
          IF YOU ARE A USER FROM NEW JERSEY, THE FOREGOING SECTIONS TITLED “DISCLAIMER OF WARRANTIES” AND “LIMITATION OF LIABILITY” ARE INTENDED TO BE ONLY AS BROAD AS IS PERMITTED UNDER THE LAWS OF THE STATE OF NEW JERSEY. IF ANY PORTION OF THESE SECTIONS IS HELD TO BE INVALID UNDER THE LAWS OF THE STATE OF NEW JERSEY, THE INVALIDITY OF SUCH PORTION SHALL NOT AFFECT THE VALIDITY OF THE REMAINING PORTIONS OF THE APPLICABLE SECTIONS.
                </p>

        <h3>CHANGES</h3>
        <p>We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.</p>

        <h3>CONTACT US</h3>
        <p>For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at enigmibot@obsidianart.com</p>

      </div>
    </div>

  </Layout>
)

export default injectIntl(PrivacyPolicy)


//   < a
// href = "#"
// onClick = { e => {
//   e.preventDefault()
//   navigate("/")
// }}
//     >
//   { intl.formatMessage({ id: "go_back" }) }
//     </a >